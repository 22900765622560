import React from 'react'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'
import { Link } from '@reach/router'

import { MainLayout } from '../components'

import '../styles/terms-of-service.scss'

export default function NotFoundPage() {
	return (
		<>
			<MainLayout>
				<Helmet title="Deenee: Privacy Policy" />
				<main className="terms-of-service-page container flex flex-col items-center justify-center mb-16">
					<header className="pt-4 mb-3">
						<h1 className="mb-8 text-center font-bold text-2xl leading-snug font-secondary">
							Terms of Service
						</h1>
						<p>
							Welcome to Deenee! We&apos;re pleased you want to use Denee. In
							order to protect your rights and those of any other user or third
							party who interacts with us, we created these Terms of Service (
							<strong>&quot;Terms&quot;</strong>). By accessing, or using, the
							Deenee mobile application or any of its products, services, and/or
							content made available by Alif Creations LTD. (
							<strong>&quot;Deenee Service&quot;</strong>,&nbsp;
							<strong>&quot;we&quot;</strong>, and{' '}
							<strong>&quot;us&quot;</strong>
							), you agree to these Terms. If you have any questions, please
							contact us at&nbsp;
							<a
								href="mailto:info@deeneeapp.com"
								target="_blank"
								rel="noreferrer"
								className="font-bold text-blue-500 hover:text-blue-600 focus:text-blue-600"
							>
								info@deeneeapp.com
							</a>
							.
						</p>
					</header>
					<section className="mb-4 max-w-full">
						<ol className="list-inside list-decimal">
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									You and Your Account
								</h2>
								<p className="mb-3">
									By registering for Deenee Service you affirm that you
									are&nbsp;
									<ol className="inline">
										<li className="inline">
											(i) 18 years of age or the applicable age of majority or
											older in your province, territory, or country,
										</li>
										<span>&nbsp;or&nbsp;</span>
										<li className="inline">(ii) an emancipated minor.</li>
									</ol>
									&nbsp;You also affirm that you are fully able and competent to
									enter into these Terms and to abide by and comply with them.
								</p>
								<p className="mb-3">
									If you register and subscribe, you agree to:&nbsp;
									<ol className="inline">
										<li className="inline">
											(a) provide accurate and current information as prompted
											by the registration form;&nbsp;
										</li>
										<li className="inline">
											(b) maintain and update such information so that it is
											accurate and current at all times;&nbsp;
										</li>
										<li className="inline">
											(c) not share your password or unique ID or let anyone
											else access your account other than in those situations
											permitted under these Terms, or do anything else that
											might jeopardize the security of your account;&nbsp;
										</li>
										<li className="inline">
											(d) not use a username with the intent to impersonate
											another person or in a manner that infringes another
											person&apos;s rights;&nbsp;
										</li>
										<li className="inline">
											(e) not use a username that is obscene or vulgar;&nbsp;
										</li>
										<span>and&nbsp;</span>
										<li className="inline">
											(f) immediately notify us of any unauthorised use of your
											password or any other breach of security related to Deenee
											Service.&nbsp;
										</li>
									</ol>
									When you share your password or allow a third party to access
									your Deenee account, you agree to remain responsible for
									compliance with these terms by any such third party. We will
									not be liable for any loss or damage arising from your failure
									to adequately safeguard your password or for any actions
									occurring under your password.
								</p>
								<p>
									Your use of Deenee Service is subject to all applicable
									technical and non- technical specifications, guidelines and
									policies which we may provide from time to time including but
									not limited to the Privacy Policy located at&nbsp;
									<Link
										to="/privacy-policy"
										className="font-bold text-blue-500 hover:text-blue-600 focus:text-blue-600 break-all"
									>
										www.deeneeapp.com/privacy
									</Link>
									&nbsp;(hereinafter collectively referred to as &quot;the
									Policies&quot;) . You agree and undertake to comply with the
									Policies.
								</p>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									Subscription Members
								</h2>
								<p>
									Although certain features and/or content of Deenee Service may
									be available at no charge to any user, other features and/or
									content may only be available to who agree to pay for a
									subscription plan (each, a &quot;Subscription Member&quot;).
									Each subscription plan may be described in more detail in
									other areas of the Deenee Service. Any payment terms displayed
									to you in the process of becoming a Subscription Member are
									deemed part of these Terms. You shall pay all fees or charges
									to your account in accordance with the fees, charges, and
									billing terms in effect at the time a fee or charge is due and
									payable. If auto-renewal is enabled for the Deenee Service you
									have subscribed for, you will be charged automatically in
									accordance with the term you selected. We reserve the right to
									change products and product pricing at any time.
								</p>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									Our License to You
								</h2>
								<p>
									We grant you a personal, revocable, non-exclusive,
									non-transferable, non-sublicensable, world-wide, license to
									access and use Deenee Service solely for personal,
									non-commercial purposes as permitted by these Terms and
									intended by us through the normal functionality of Deenee
									Service. This license to you will automatically terminate if
									you violate these Terms. Except for the foregoing limited
									license, no right, title or interest is transferred to you. We
									may allow you to store encrypted, cached content on your
									tablet, smartphone or other compatible internet-connected
									device. You may not transfer copies of cached content to any
									other device.
								</p>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									Your Conduct
								</h2>
								<p className="mb-0.5">
									We would like to keep Deenee Service as safe as possible for
									our users but need your help to do so. Accordingly, you agree
									not to use Deenee Service to:&nbsp;
									<ol className="inline">
										<li className="inline">
											(i) violate any local, state, national, or international
											law or regulation;&nbsp;
										</li>
										<li className="inline">
											(ii) transmit any material that is abusive, harassing,
											tortious, defamatory, vulgar, pornographic, obscene,
											libelous, graphically violent, invasive of another&apos;s
											privacy, hateful, or racially, ethnically, or otherwise
											objectionable;&nbsp;
										</li>
										<li className="inline">
											(iii) transmit any unsolicited or unauthorized
											advertising, promotional materials, junk mail, spam, chain
											letters, pyramid schemes, or any other form of
											solicitation;&nbsp;
										</li>
										<li className="inline">
											(iv) knowingly transmit any material that contains adware,
											malware, spyware, software viruses, or any other computer
											code, files, or programs designed to interrupt, destroy,
											or limit the functionality of any computer software or
											hardware or telecommunications equipment;&nbsp;
										</li>
										<li className="inline">
											(v) breach or circumvent any security or authentication
											measures (including geo-restriction measures);&nbsp;
										</li>
										<li className="inline">
											(vi) attempt to decompile or reverse engineer any software
											contained on Deenee Service;&nbsp;
										</li>
										<li className="inline">
											(vii) remove any copyright, trademark, or other
											proprietary notations from any Content (as defined below)
											or User Content (as defined below) of other users;&nbsp;
										</li>
										<li className="inline">
											(viii) stalk, harass, or harm another individual;&nbsp;
										</li>
										<li className="inline">
											(ix) impersonate any person or entity, or otherwise
											misrepresent your affiliation with a person or
											entity;&nbsp;
										</li>
										<li className="inline">
											(x) interfere with or disrupt Deenee Service or servers or
											networks connected to Deenee Service, or disobey any
											requirements, procedures, policies, or regulations of
											networks connected to Deenee Service;&nbsp;
										</li>
										<span>&nbsp;or&nbsp;</span>
										<li className="inline">
											(xi) rent or lease your account. You also agree not to use
											or launch any automated system, including without
											limitation, &quot;robots,&quot; &quot;spiders,&quot;
											&quot;offline readers,&quot; etc., that accesses Deenee
											Service in a manner that sends more request messages to
											Deenee Service servers in a given period of time than a
											human can reasonably produce in the same period by using a
											conventional web browser.
										</li>
									</ol>
								</p>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									Ownership; Restrictions On Use
								</h2>
								<p>
									All right, title, and interest in and to Deenee Service are
									and will remain the exclusive property of Alif Creations LTD.
									The content on Deenee Service, including without limitation,
									text, software, graphics, photos, sounds, animations,
									characters, videos, User Content (as defined below),
									interactive features and the like (&quot;Content&quot;) and
									the &quot;Deenee&quot; word mark and design mark, as well as
									certain other of the names, logos, and materials displayed on
									or through Deenee Service that constitute trademarks,
									tradenames, service marks or logos (&quot;Marks&quot;) are
									owned by or licensed to Alif Creations LTD and are subject to
									copyright, trademark, and other intellectual property rights
									under U.K. and international laws. You agree not to reproduce,
									duplicate, copy, sell, resell, or exploit for any purposes
									Deenee Service or any portion of Deenee Service, including,
									without limitation, Content and Marks, except as authorized by
									these Terms or as otherwise authorized in writing by us or our
									applicable licensors. Deenee Service is provided for your
									personal, non-commercial use only. You must abide by all
									copyright notices, information, or restrictions contained in
									or associated with any Content.
								</p>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									User Content
								</h2>
								<p className="mb-3">
									We may provide functionality that enables you to post, upload,
									submit or otherwise make available text, images, audio, video,
									presentations, competition entries or other content (“User
									Content”) to Deenee Service. Access to such functionality may
									be restricted to users above a certain age. You understand
									that such User Content may be accessible by the public and we
									do not guarantee any confidentiality for such User Content.
								</p>
								<p className="mb-3">
									You retain all of your ownership rights to your User Content.
									By making User Content available to Deenee Service, you grant
									us a worldwide, non-exclusive, royalty-free, sublicensable and
									transferable license under all intellectual property rights to
									use, reproduce, distribute, display, and perform such User
									Content. You also grant each user of Deenee Service a
									non-exclusive license to view your User Submission through
									Deenee Service, and to use, reproduce, distribute, display and
									perform such User Submission as permitted through the normal
									functionality of Deenee Service and these Terms in perpetuity.
								</p>
								<p className="mb-3">
									You may not submit User Content that are&nbsp;
									<ol className="inline">
										<li className="inline">
											(i) defamatory, harassing, threatening, bigoted, hateful,
											violent, vulgar, obscene, pornographic, or otherwise
											offensive or that harms or can reasonably be expected to
											harm any person or entity, in each case whether or not
											such content is protected by law,
										</li>
										<span>&nbsp;or&nbsp;</span>
										<li className="inline">
											(ii) otherwise contrary to applicable local, national, and
											international laws and regulations.
										</li>
									</ol>
								</p>
								<p className="mb-3">
									You may not submit User Content that contain third party
									copyrighted material, or material that is subject to other
									third party proprietary rights, unless you have permission
									from the rightful owner of the material or you are otherwise
									legally authorized to submit such content and grant us the
									licenses described above. You represent and warrant that you
									own or have the necessary licenses, rights, consents, and
									permissions to publish the User Content and that the User
									Content conform to the terms described in this Section 6. You
									also agree not to enforce any moral rights, ancillary rights
									or similar rights in or to the User Content against us or our
									licensees, distributors, agents, representatives and other
									authorized users, and agree to procure the same agreement not
									to enforce from others who may possess such rights. You
									understand and agree that you will be solely responsible for
									your User Content and the consequences of submitting and
									making available such User Content to Deenee Service.
								</p>
								<p className="mb-3">
									We have the right but not the obligation to monitor, screen,
									post, remove, modify, store and review User Content submitted
									to Deenee Service, at any time and for any reason, including
									to confirm that the User Content are consistent with these
									terms, without prior notice to you.
								</p>
								<p>
									We do not endorse or guarantee the opinions, recommendations,
									or advice contained in any User Submission and we expressly
									disclaim any and all liability in connection with such
									content.
								</p>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									Cancellation
								</h2>
								<p className="mb-3">
									You can cancel your subscription anytime. You may cancel it in
									the middle of a subscription period, but the subscription
									remains paid through the end of the same period.
								</p>
								<p>
									Please note that Deenee subscriptions purchased via the Apple
									App Store are governed and controlled by the terms and
									conditions established by Apple, and likewise subscriptions
									purchased via Google Play are governed and controlled by the
									terms and conditions established by Google.
								</p>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									Termination
								</h2>
								<p>
									Your right to use Deenee Service automatically terminates if
									you violate these Terms or any rules or guidelines posted in
									connection with Deenee Service. We reserve the right, in our
									sole discretion, to terminate your access to all or part of
									Deenee Service, for any reason, with or without notice.
								</p>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									Links or Third Party Applications/Devices
								</h2>
								<p className="mb-3">
									<strong>Links.</strong> Deenee Service may contain links to
									third party websites or interact with third party applications
									(including, without limitation, widgets, software, or other
									software utilities) that are not owned or controlled by Deenee
									Service. Our provision of a link to any other website or third
									party application is for your convenience only and does not
									signify our endorsement of such other website or resource or
									its contents. We will have no responsibility or liability for
									any content, information, software, materials or practices of
									any third party website or third party application.
								</p>
								<p>
									<strong>Devices.</strong> If you access Deenee Service using
									an Apple iOS or Android-powered device, then Apple Inc. or
									Google, Inc., respectively, will be a third-party beneficiary
									to this agreement. However, these third-party beneficiaries
									are not a party to this contract and are not responsible for
									the provision or support of Deenee Service. You agree that
									your access to Deenee Service using these devices also shall
									be subject to the usage terms set forth in the applicable
									third-party beneficiary’s terms of service.
								</p>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									Disclaimer of Warranties
								</h2>
								<p>
									You agree that use of Deenee service is at your sole risk.
									Deenee service is provided on an &quot;as is&quot; and
									&quot;as available&quot; basis. To the fullest extent
									permitted by law, Alif Creations LTD expressly disclaim all
									warranties of any kind, whether express or implied, with
									respect to Deenee service (including, but not limited to, the
									implied warranties of merchantability, fitness for a
									particular use or purpose, and non-infringement). We make no
									warranties or representations about the accuracy or
									completeness of content available on or through Deenee Service
									or the content of any websites linked to Deenee Service and
									assume no liability or responsibility for any:&nbsp;
									<ol className="inline">
										<li className="inline">
											(i) errors, mistakes, or inaccuracies of content;&nbsp;
										</li>
										<li className="inline">
											(ii) personal injury or property damage, of any nature
											whatsoever, resulting from your access to or use of Deenee
											Service or the Content on Deenee Service;&nbsp;
										</li>
										<li className="inline">
											(iii) any unauthorized access to or use of our secure
											servers and/ or any and all personal information and/or
											financial information stored therein;&nbsp;
										</li>
										<li className="inline">
											(iv) any interruption or cessation of transmission to or
											from Deenee Service;&nbsp;
										</li>
										<li className="inline">
											(v) any bugs, viruses, Trojan horses, or the like which
											may be transmitted to or through Deenee Service by any
											third party;&nbsp;
										</li>
										<span>and/or&nbsp;</span>
										<li className="inline">
											(vi) for any loss or damage of any kind incurred as a
											result of the use of any content posted, emailed,
											transmitted, or otherwise made available on or through
											Deenee Service.
										</li>
									</ol>
								</p>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									Limitation of Liability
								</h2>
								<p>
									You understand that to the extent permitted under applicable
									law, in no event will Alif Creations LTD or its affiliates,
									officers, directors, employees, agents, suppliers or licensors
									be liable for any direct, indirect, incidental, special,
									consequential or exemplary damages, including but not limited
									to, damages for loss of revenues, profits, goodwill, use, data
									or other intangible losses (even if such parties were advised
									of, knew of or should have known of the possibility of such
									damages), resulting from your (or anyone using your
									account&apos;s) use of Deenee service.
								</p>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									Exclusions
								</h2>
								<p>
									Some jurisdictions do not allow the exclusion of certain
									warranties or the limitation or exclusion of liability for
									incidental or consequential damages. Accordingly, some of the
									above limitations and disclaimers may not apply to you. To the
									extent Alif Creations LTD may not, as a matter of applicable
									law, disclaim any implied warranty or limit its liabilities,
									the scope and duration of such warranty and the extent of our
									liability shall be the minimum permitted under such applicable
									law.
								</p>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									Indemnification
								</h2>
								<p>
									You agree to indemnify and hold Alif Creations LTD and its
									affiliates, directors, officers, employees, and agents
									harmless from and against any liabilities, losses, damages or
									costs, including reasonable attorneys’ fees, incurred in
									connection with or arising from any third-party allegations,
									claims, actions, disputes, or demands asserted against any of
									them as a result of or relating to your Content, your use of
									the Mobile Application or Services or any willful misconduct
									on your part.
								</p>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									Governing law and disputes
								</h2>
								<p>
									These terms and conditions are governed by English law. You
									will resolve any claim, cause of action or dispute you have
									with us arising out of or relating to these Terms or Deenee
									Service exclusively in the courts of England and Wales.
								</p>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									Changes and amendments
								</h2>
								<p>
									We reserve the right to change these Terms at any time,
									effective upon posting of an updated version of this Agreement
									in the Mobile Application. When we do we will revise the
									updated date at the bottom of this page. Continued use of the
									Mobile Application after any such changes shall constitute
									your consent to such changes.
								</p>
							</li>
						</ol>
					</section>
					<footer className="w-full">
						<p>
							Last updated: <time>March 15, 2020</time>.
						</p>
					</footer>
				</main>
			</MainLayout>
			<ToastContainer />
		</>
	)
}
